import { Card, CardContent, Container, Divider, Typography } from '@mui/material';
import React from 'react';
import '../css/ComponenteVerificar.css';
import logo from '../img/logo.png';
import ValidateFiles from './ValidateFiles';

const ComponenteVerificar = () => {
  return (
    <Container maxWidth="lg">
        <Card variant="outlined" className="cardContainer">
            <CardContent>
                <Typography variant="h4" className='title' gutterBottom>
                    Validar Documento
                </Typography>
                <Typography variant="body1" paragraph className='bodyVerificar'>
                    Por favor, selecciona un archivo PDF que desees validar si están firmados digitalmente. Una vez que selecciones el archivo, haz clic en el botón "Verificar" para iniciar el proceso de validación de firma digital. 
                </Typography>
                <ValidateFiles />
            </CardContent>
            <Divider />
            <CardContent className="cardFooterContent">
                <div className="logoContainer">
                    <img src={logo} alt="Poder Judicial" className='imgVerificar' />
                </div>
            </CardContent>
        </Card>
    </Container>
  )
}

export default ComponenteVerificar;

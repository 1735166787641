import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Alert, Box, Button, CircularProgress, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import '../css/ValidateFiles.css';
import CustomSnackbar from './CustomSnackbar';
import SignatureCards from './SignatureCards';

const ValidateFiles = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [uploading, setUploading] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');
    const [signatures, setSignatures] = useState([]);
    const [noSignaturesMessage, setNoSignaturesMessage] = useState(false);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file && file.type === 'application/pdf') {
            setSelectedFile(file);
            setSignatures([]); 
            setNoSignaturesMessage(false);
        } else {
            setSnackbarMessage('Solo se permite subir archivos PDF');
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
        }
    };

    const handleUpload = async () => {
        setUploading(true);
        const formData = new FormData();
        formData.append('file', selectedFile);

        try {
            const apiUrl = process.env.REACT_APP_VALIDATE_FILES;
            const response = await axios.post(apiUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            // console.log('File uploaded successfully:', response.data);
            const signaturesResponse = response.data.signatures;
            if (Object.keys(signaturesResponse).length === 0) {
                setNoSignaturesMessage(true);
            } else {
                setSignatures(signaturesResponse);
            }
            setSnackbarMessage('Archivo subido exitosamente');
            setSnackbarSeverity('success');
        } catch (error) {
            console.error('Error al subir el archivo:', error);
            setSnackbarMessage('Error al subir el archivo. Intente de nuevo');
            setSnackbarSeverity('error');
        } finally {
            setUploading(false);
            setOpenSnackbar(true);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };


    return (
        <Box>
            <Box mb={2}>
                <input
                    type="file"
                    accept=".pdf"
                    onChange={handleFileChange}
                    className='inputValidateFiles'
                    id="file-upload"
                />
                <label htmlFor="file-upload">
                    <Button
                        variant="outlined"
                        component="span"
                        className='buttonSeleccionarArchivos'
                        startIcon={<CloudUploadIcon />}
                    >
                        Seleccionar archivo
                    </Button>
                </label>
            </Box>
            {selectedFile && (
                <Box mb={2}>
                    <Typography variant="body1" className='nameFileSelected'>
                        Archivo seleccionado: {selectedFile.name}
                    </Typography>
                </Box>
            )}
            <Box mb={2}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleUpload}
                    disabled={!selectedFile || uploading}
                    startIcon={<CheckCircleOutlineIcon />}
                    className='buttonValidateFiles'
                    style={{
                        backgroundColor: (uploading || !selectedFile) ? undefined : "#FF9015"
                    }}
                >
                    {uploading ? <CircularProgress size={24} color="inherit" /> : 'Verificar'}
                </Button>
            </Box>
            <CustomSnackbar
                open={openSnackbar}
                message={snackbarMessage}
                severity={snackbarSeverity}
                onClose={handleCloseSnackbar}
            />
            {Object.keys(signatures).length > 0 ? (
                <SignatureCards signatures={signatures} />
            ) : (
                noSignaturesMessage && (
                    <Alert severity="warning" variant="outlined" sx={{ my: 2 }}>
                        <Typography variant="body1">
                            No tiene ninguna firma este documento
                        </Typography>
                    </Alert>
                )
            )}
        </Box>
    );
}

export default ValidateFiles;

import { AccessTime as AccessTimeIcon, AlarmOn as AlarmOnIcon, Assignment as AssignmentIcon, CalendarToday as CalendarTodayIcon, ExpandMore as ExpandMoreIcon, ListAlt as ListAltIcon, PlaylistAddCheck as PlaylistAddCheckIcon, VerifiedUser as VerifiedUserIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import React from 'react';
import '../css/SignatureCards.css';

const SignatureCard = ({ signatureData }) => {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric',hour: 'numeric', minute: 'numeric' };
        return date.toLocaleDateString('es-ES', options);
    };

    const extractCN = (issuerDN) => {
        const regex = /CN=([^,]+)/;
        const match = issuerDN.match(regex);
        return match ? match[1] : 'No disponible';
    };

    const getColorForValidity = (data) => {
        if (data.verificationResult.result === 'VALID' && data.certificateInfo !== null) {
            return 'green';
        } else if (data.verificationResult.result === 'CERTIFICATE_EXPIRED' && data.certificateInfo !== null) {
            return '#FF9015';
        } else {
            return 'red';
        }
    };

    return (
        <Accordion style={{ border: `2px solid ${getColorForValidity(signatureData)}`, marginBottom: "20px" }}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <Typography className='text' variant="h6" >Firma {signatureData.name || 'No disponible'} : <strong style={{ color: "#1976d2" }}>{signatureData.signName}</strong></Typography>
            </AccordionSummary>
            <AccordionDetails>
                <div className="signature-content">
                    <div className="signature-details">
                        <div className="sign-item">
                            <AssignmentIcon className="icon" />
                            <Typography className='text' variant="body1">Nombre del archivo: {signatureData.signName || 'No disponible'}</Typography>
                        </div>
                        <div className="sign-item">
                            <AccessTimeIcon className="icon" />
                            <Typography className='text' variant="body1">Fecha de firma: {formatDate(signatureData.signDate) || 'No disponible'}</Typography>
                        </div>
                        <div className="sign-item">
                            <VerifiedUserIcon className="icon" />
                            <Typography className='text' variant="body1">Resultado de verificación: <strong style={{ color: getColorForValidity(signatureData) }}>{signatureData.verificationResult?.result || 'No disponible'}</strong></Typography>
                        </div>
                        <div className="sign-item">
                            <AlarmOnIcon className="icon" />
                            <Typography className='text' variant="body1">Mensaje de verificación: {signatureData.verificationResult?.message || 'No disponible'}</Typography>
                        </div>
                        <div className="sign-item">
                            <ListAltIcon className="icon" />
                            <Typography className='text' variant="body1">Total de revisiones: {signatureData.revision || 'No disponible'}</Typography>
                        </div>
                        {signatureData.certificateInfo !== null ? (<div className="sign-item">
                            <PlaylistAddCheckIcon className="icon" />
                            <Typography className='text' variant="body1">Certificado por: <strong>{extractCN(signatureData.certificateInfo?.issuerDN)}</strong></Typography>
                        </div>) : (<div className="sign-item">
                            <PlaylistAddCheckIcon className="icon" />
                            <Typography className='textError' variant="body1"><strong>No tiene certificado válido</strong></Typography>
                        </div>)}

                        <div className="sign-item">
                            <CalendarTodayIcon className="icon" />
                            <Typography className='text' variant="body1">Válido desde {formatDate(signatureData.certificateInfo?.notValidBefore)} hasta {formatDate(signatureData.certificateInfo?.notValidAfter)}</Typography>
                        </div>
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    );
};

const SignatureCards = ({ signatures }) => {
    return (
        <div className="signature-cards-container">
            {Object.entries(signatures).map(([signatureId, signatureData]) => (
                <SignatureCard key={signatureId} signatureData={signatureData} />
            ))}
        </div>
    );
};

export default SignatureCards;
